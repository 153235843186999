@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Lato !important;
}

a {
  color: #4385F5 !important;
  text-decoration: none !important;
}

a:hover {
  text-decoration: underline !important;
}

:root {
  --primary: "#4385F5",
    --secondary:'#34A853',
    --yellow:'#FABC05',
    --graymain:'#F2F2F2',

}

/* .background_Image {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: cover !important;
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)), url("./assets/images/bg.svg");
} */

.background_color {
  background-color: #f8f8f8 !important;
}

.logo_img {
  max-width: 170px !important;
}

.description-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* Spacing between each description box */
  padding: 20px;
  /* Padding for the container */
}

.description-box {
  background-color: #f9f9f9;
  /* Light background for the box */
  border: 1px solid #ddd;
  /* Subtle border */
  border-radius: 5px;
  /* Rounded corners */
  padding: 15px;
  /* Padding inside the box */
  display: flex;
  justify-content: space-between;
  /* Space out content and actions */
  align-items: center;
  /* Center items vertically */
}

.description-text {
  margin: 0;
  /* Remove default margin */
  flex-grow: 1;
  /* Allow text to grow and take available space */
}

.description-actions {
  display: flex;
  align-items: center;
  /* Center actions vertically */
}

.description-actions>* {
  margin-left: 10px;
  /* Spacing between icons */
}

.description-actions svg:hover {
  fill: #007bff;
  /* Change color on hover (optional) */
}

.small_logo_img {
  max-width: 30px !important;
}

/*--------------------- 
        Scrollbar css 
----------------------------------*/
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  position: absolute;
  display: none;
  scrollbar-gutter: stable both-edges;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #e4e4e4;
  border-radius: 10px;
  border: 2px solid transparent;
}

/*--------------------- 
        Scrollbar css 
----------------------------------*/

.tooltip_custom {
  z-index: 9999999 !important;
}

/* .scrollable-content {
  margin-left: auto;
  margin-right: auto;
}
.scrollable-content:hover,
.scrollable-content:active,
.scrollable-content:focus {
  overflow-y: scroll;
} */

.background_gradient {
  background: linear-gradient(#0009, #0009);
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}


/*--------------------- 
        MUI css 
----------------------------------*/
.sidebar_text span {
  font-size: 14px !important;
  line-height: 1 !important;
}

.active_sidebar {
  background: #fef9f8 !important;
}

.card_container {
  background: #fff;
  border-radius: 12px;
  padding: 40px;
}

.border_card {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  /* background: #f8f8f8 !important; */
}

/* .card_container:hover{
  -webkit-box-shadow: 15px 23px 22px -24px rgba(56,56,56,0.62);
  -moz-box-shadow: 15px 23px 22px -24px rgba(56,56,56,0.62);
  box-shadow: 15px 23px 22px -24px rgba(56,56,56,0.62);
} */
.disable_button .Mui-disabled {
  background-color: #4385F5 !important;
  color: #fff !important;
}

.category_table_row .MuiTableCell-root {
  font-weight: 600 !important;
}

.category_table_row {
  background: #4385f512 !important;
}

.specification_table .MuiTableRow-root:hover {
  box-shadow: unset !important;
  background: unset !important;
}

.custome_tab_menu {
  background: #fff;
  border-radius: 10px;
  padding: 7px;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.table-hover {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custome_tab_menu div {
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width:600px) {
  .sidebar_text span {
    font-size: 16px !important;
  }
}

.box_shadow {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 10px;
}

.purchase_order_table .MuiTableCell-root {
  padding: 10px 15px !important;
}

.purchase_order_table .MuiTableRow-root:hover {
  box-shadow: unset !important;
  background: unset !important;
}



.price_input_main {}

.input_select fieldset {
  /* border: unset !important; */
  /* border-right: 1px solid rgba(0, 0, 0, 0.12) !important; */
  /* border-radius: unset !important; */
}

.input_select .MuiSvgIcon-root {
  right: 3px !important;
  width: 0.8em !important;
}

.input_select .MuiSelect-select {
  padding: 5px 28px 5px 10px !important;
}

.select_input input {
  padding: 10px 20px 10px 60px !important;
}

/*--------------------- 
        MUI css 
----------------------------------*/

/*--------------------- 
        DateRange css 
----------------------------------*/
.date_range {
  width: max-content !important;
}

.date_range button {
  background: #fff;
  color: #000;
  font-weight: 400;
  border: unset !important;
  border-radius: 6px;
  padding: 11px 10px;
  text-align: start;
  font-size: 13px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  /* box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25); */
}

.date_range_width button {
  width: unset !important;
  background: #4385F5 !important;
  color: #fff !important;
}

.daterangepicker .ranges li.active {
  background-color: #4385F5 !important;
}

.daterangepicker.opensright:before,
.daterangepicker.opensright:after {
  right: 20px !important;
  left: unset !important;
  display: none !important;
}

.show-calendar {
  right: 20px !important;
  overflow: scroll;
  height: 303px;
}

.applyBtn {
  background: #4385F5 !important;
  border: 1px solid #4385F5 !important;
}

.daterangepicker td.in-range {
  background-color: #4385f512 !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #4385F5 !important;
}

.date_range_icon {
  padding: 4px;
}

.date_range_icon svg {
  font-size: 22px;
}

@media (max-width:600px) {
  .daterangepicker {
    right: 20px !important;
  }

  .date_range button {
    padding: 13px 10px;
    font-size: 14px;
  }

  .date_range_icon {
    padding: 3px;
    display: none !important;
  }

  .date_range_icon svg {
    font-size: 18px;
  }
}





/*--------------------- 
        DateRange css 
----------------------------------*/

/*--------------------- 
        Radix UI css 
----------------------------------*/
.contactDropDown,
.profileDropDownTrigger,
.notificationDropDownTrigger,
.chatDropDown {
  background: transparent;
  display: flex;
  border: unset !important;
  outline: unset !important;
}

[data-radix-popper-content-wrapper] {
  top: 9px !important;
  font-weight: 400;
}

.contactDropDownContent,
.profileDropDownContent,
.notificationDropDownContent,
.chatDropDownContent {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.12);
  z-index: 1000;
  padding: 5px;
}

.contactDropDownMenu,
.profileDropDownMenu,
.notificationDropDownMenu,
.chatDropDownMenu {
  border: unset !important;
  outline: unset !important;
  font-size: 14px !important;
  cursor: pointer;
}

.profileDropDownMenu {
  padding: 12px
}

.profileDropDownMenu:hover {
  background: #4385f512;
}

.notificationDropDownMenu {
  padding: 10px;
}

.profileDropDownContent {
  padding: 8px !important;
}

.contactDropDownMenu,
.chatDropDownMenu {
  padding: 7px 10px;
}

.chatDropDownMenu:hover {
  background: #4385f512;
}

.contactDropDown .MuiButtonBase-root {
  width: 100%;
}

.contactDropDownMenu:hover {
  background: #4385f512;
}

.profileDropDown [data-radix-popper-content-wrapper] {
  left: 53px !important;
  top: 47px !important;
  width: 250px !important;
}

.upload_product_modal .MuiDialog-paper {
  max-width: 100% !important;
}

/*--------------------- 
        Radix UI css 
----------------------------------*/

.example {
  width: 80%;
  height: min-content;
  stroke: #4385F5;
  padding: 4%;
  /* border: 1px solid #eaeef5; */
}

.price_input_main,
.price_input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.price_input_adjustment {
  gap: 5px;
}

.price_input fieldset {}

.price_input input {
  width: 150px !important;
  padding: 7px 15px !important;
}

.price_input_main input {
  width: 95px !important;
  padding: 7px 15px !important;
}

.input_select {
  width: 50px !important;
}

.price_input p {
  margin-bottom: 0 !important;
}

.MuiFormHelperText-root {
  font-size: 10px !important;
}

.MuiInput-underline .MuiSelect-select {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background: transparent !important;
}

.create_purchase_vendor .MuiAutocomplete-root,
.create_purchase_vendor .MuiBox-root {
  width: 100%;
  max-width: 250px;
}

.create_purchase_vendor .MuiInputBase-root {
  padding: 0 !important;
}

.payment_made_input {
  width: 150px !important;
}

.payment_made_input input {
  text-align: end !important;
}

.MuiAccordion-root {
  box-shadow: unset !important;
  border: 1px solid #00000030 !important;
  border-radius: 6px !important;
}

.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

.MuiAccordion-root::before {
  background-color: unset !important;
}

.report_head .MuiTypography-root {
  text-align: start !important;
  margin-bottom: 0 !important;
}

.report_body .MuiTableCell-root:last-child {
  text-align: center !important;
}

.dashboard_head .MuiFormControl-root {
  width: 170px !important;
}

.inv_add .MuiFormControl-root,
.inv_add .MuiSelect-select {
  width: 70px !important;
}

.inv_add .MuiTextField-root {
  width: 100% !important;
}

.credit_input {
  width: 170px !important;
}

.credit_input input {
  text-align: end !important;
}

.tabs-with-slider {
  width: 100%;
  max-width: 350px;
  text-align: center;
}

.tabs-group {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  padding: 8px;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #F2F2F2;
}

.tab-button {
  flex: 1;
  cursor: pointer;
  /* transition: color 0.3s ease-in; */
  z-index: 1;
}

.tab-button.active {
  color: white;
}

.slider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: #4385F5;
  border-radius: 5px;
  transition: left 0.3s ease-out;
  z-index: 0;
}

.tab-content {
  padding: 10px;
}

.largetext {
  font-size: 17px;
  font-weight: 800;
  color: #000;
}

.mainh1 {
  font-size: 16px;
  font-weight: 700;
  color: #000;
}

.mainh2 {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.mainh3 {
  font-size: 12px;
  font-weight: normal;
  color: #F2F2F2;
}

.listbutton {
  padding: 10px 15px;
  border: 1px solid #4385F5;
  border-radius: 5px;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  font-size: 14px;
}

.listbutton:hover {
  background-color: #4385F5;
  color: white;
}

.selectedlistbutton {
  padding: 10px 15px;
  background-color: #4385F5;
  border-radius: 5px;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  color: white;
  font-size: 14px;
}

.cardheader {
  padding-bottom: 0% !important;
}